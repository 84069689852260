import { Auth } from 'aws-amplify'
import { API } from 'aws-amplify'
import * as queries from '@/src/graphql/queries'

/* 
Runs first in to protect Admin routes from non-admin users
*/

export default defineNuxtRouteMiddleware(async (to, from, next) => {
  const authStore = useAuthStore()
  const { user } = authStore

  try {
    const dashboardGroups = ['Admin', 'Partner']
    if (!dashboardGroups.includes(user.group)) {
      return navigateTo('/')
    }
    return // allow access to admin
  } catch (error) {
    if (to.path === '/login') {
      return
    } else {
      return navigateTo('/')
    }
  }
})
